import {createStore, applyMiddleware, combineReducers} from 'redux';
import {combineEpics, createEpicMiddleware} from 'redux-observable';
import { compose } from 'redux';
import { createLogger } from 'redux-logger';
import {IAppState} from './react-app-env';
import {beerReducer} from "./areas/beer/beers-reducer";
import {
    createBeerEpic,
    createBeerSuccessEpic,
    getBeersEpic,
    selectBeerEpic,
    updateBeerEpic
} from "./areas/beer/beers-epic";
import {tapsReducer} from "./areas/taps/taps-reducer";
import {
    connectTappedBeerEpic,
    connectTappedBeerSuccessEpic,
    getTapsEpic, netlifytBuildEpic,
    unconnectTappedBeerEpic, unconnectTappedBeerSuccessEpic
} from "./areas/taps/taps-epic";
import {userReducer} from "./common/user/user-reducer";
import {homeReducer} from "./areas/home/home-reducer";
import {getHomeTestEpic} from "./areas/home/home-epic";
import {hoursReducer} from "./areas/hours/hours-reducer";
import {
    createHoursEpic,
    deleteHoursEpic,
    getHoursEpic,
    selectHourEpic,
    updateHoursEpic
} from "./areas/hours/hours-epic";
import {eventReducer} from "./areas/event/event-reducer";
import {getEventsEpic} from "./areas/event/event-epic";

const epicMiddleware = createEpicMiddleware();
const logger = createLogger();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {


    // const rootReducer = combineReducers<IAppState>({
    const rootReducer = combineReducers({
        user: userReducer,
        home: homeReducer,
        beer: beerReducer,
        event: eventReducer,
        taps: tapsReducer,
        hours: hoursReducer
    });

    const rootEpic = combineEpics(
        getHomeTestEpic,
        getBeersEpic,
        selectBeerEpic,
        createBeerEpic,
        createBeerSuccessEpic,
        updateBeerEpic,
        getEventsEpic,
        getTapsEpic,
        connectTappedBeerEpic,
        connectTappedBeerSuccessEpic,
        unconnectTappedBeerEpic,
        unconnectTappedBeerSuccessEpic,
        netlifytBuildEpic,
        createHoursEpic,
        getHoursEpic,
        selectHourEpic,
        updateHoursEpic,
        deleteHoursEpic
    );



    const store = createStore(rootReducer,
        composeEnhancers(
          applyMiddleware(logger, epicMiddleware)
        )
      );
  
    epicMiddleware.run(rootEpic);
  
    return store;
  }
import * as React from "react";
import {connect} from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {IGraphBeer, IGraphTap} from "../../hf-admin-types";
import {IAppState} from "../../react-app-env";
import {getBeers} from "../beer/beers-actions";

const TapsRowStyles = styled.tr`
    
    td {
        vertical-align: middle;
    }  
    
    select:required:invalid {
        color: gray;
        font-style: italic;
    }
    option[value=""][disabled] {
        display: none;
    }
    option {
        color: black;
        font-style: normal;
    }
`

interface ITapsRowProps {
    tap: IGraphTap;
    beers: IGraphBeer[];
    //TODO: Handle Return for Child Functions
    onConnect: (tap, beer) => void;
    onUnconnect: (id) => void;
}

export class TapsRow extends React.Component<ITapsRowProps> {


    onComponentDidMount = () => {
        console.log('Mount');
        !this.props.tap.tappedbeer ? getBeers() : null;
    }


    handleDisconnectClick = () => {
        this.props.onUnconnect(this.props.tap);
    }

    handleConnectChange = (e) => {
        this.props.onConnect(this.props.tap.id, e.target.value);
    }

    render() {
        return (

                        <TapsRowStyles>
                                <td className='text-center'>{this.props.tap.tapnumber}</td>
                                <td>
                                    {this.props.tap.tappedbeer
                                        ? this.props.tap.tappedbeer.name
                                        : (
                                            <select required defaultValue="" onChange={this.handleConnectChange}
                                                    className="form-control">
                                                <option value="" disabled>Back Real Soon</option>
                                                {this.props.beers.map((beer: any) => (
                                                    <option value={beer.id}
                                                            key={`tap-${this.props.tap.tapnumber}-row-beer-${beer.id}`}>{beer.name}</option>
                                                ))
                                                }
                                            </select>
                                        )
                                    }
                                </td>
                                <td className='text-center d-none d-sm-table-cell'>{this.props.tap.tappedbeer
                                    ? `${this.props.tap.tappedbeer.style}`
                                    : null}</td>
                                <td className='pointer text-right' onClick={this.handleDisconnectClick}>X</td>
                    </TapsRowStyles>


        );
    }
}


import {AppEpic} from "../../react-app-env";
import {ofType} from "redux-observable";
import {map, switchMap} from "rxjs/operators";
import {ajax, AjaxResponse} from "rxjs/ajax";
import {getHomeTestSuccess} from "./home-actions";

export const getHomeTestEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('HOMETEST_REQUEST'),
        switchMap(action =>

            // TODO: generateHeaders
            ajax({
                url: `/.netlify/functions/graph`,
                method: 'PUT',
                responseType: 'json',
                body: `{
                        taps {
                            id
                            tapnumber
                            tappedbeer {
                                name
                            }
                        }
                    }`
            })),
            // ajax({
            //     url: `/.netlify/functions/async`,
            //     method: 'GET',
            //     responseType: 'json'
            // })),
        map((response) => getHomeTestSuccess(response.response.data))
        // catch(e => Observable.of(getHomeTestError(e.message)))
    );
import {IGraphTap} from "../../hf-admin-types";

const initialState = {
    taps: [],
    tapsLoaded: false,
    tapsLoading: false,
    tapsChanged: false
}

export const tapsReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'TAPS_REQUEST':
            return {...state, tapsLoading: true };
        case 'TAPS_REQUEST_SUCCESS':
            return {...state, taps: action.payload, tapsLoading: false };

        case 'CONNECT_TAPPED_BEER':
        case 'UNCONNECT_TAPPED_BEER':
        case 'NETLIFY_BUILD_REQUEST'
   :         return {...state, tapsLoading: true };
        case 'CONNECT_TAPPED_BEER_SUCCESS':
        case 'UNCONNECT_TAPPED_BEER_SUCCESS':
            return {...state, tapsLoading: false, tapsChanged: true };
        case 'NETLIFY_BUILD_SUCCESS':
            return {...state, tapsLoading: false, tapsChanged: false };
        default:
            return state;
    }
}

const initialState = {
    user: {},
    email: '',
    isAuth: false
}

export const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'USER_REQUEST_SUCCESS':
            return {...state,
                user: action.payload,
                email: action.payload.email,
                isAuth: action.payload.token.hasOwnProperty('access_token')
            };

        case 'USER_LOGOUT':
            return {...initialState};

        default:
            return state;
    }
}


const initialState = {
    hoursId: '',
    hoursDate: undefined,
    hoursAbbr: '',
    hoursStartTime: '',
    hoursEndTime: '',
    hoursClosed: false,

    hours: [],
    hoursLoaded: false,
    hoursLoading: false
}

export const hoursReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'HOURS_REQUEST':
            return {...state, hoursLoading: true };
        case 'HOURS_REQUEST_SUCCESS':
            return {...state, hours: action.payload, hoursLoaded: true, hoursLoading: false };

        case 'HOUR_SELECT':
            return {...state, hoursLoading: true };
        case 'HOUR_SELECT_SUCCESS':
            return {...state,

                hoursId: action.payload.id || '',
                hoursDate: new Date(action.payload.date) || undefined,
                hoursAbbr: action.payload.abbr || '',
                hoursStartTime: action.payload.startTime || '',
                hoursEndTime: action.payload.endTime || '',
                hoursClosed: action.payload.closed,
                hoursLoading: false
            };
        case 'HOUR_SELECT_ERROR':
            return {...state, hoursLoading: false };
        case 'HOUR_CLEAR':
            return {...state,
                hoursId: '',
                hoursAbbr: '',
                hoursStartTime: '',
                hoursEndTime: '',
                hoursClosed: false,
            };            


        case 'HOURS_CREATE':
            return {...state, hoursLoading: true };
        case 'HOURS_CREATE_SUCCESS':
            return {...state, hoursId: action.payload.id, hoursLoading: false };
        case 'HOURS_CREATE_ERROR':
            return {...state, hoursLoading: false };

        case 'HOUR_UPDATE':
            return {...state, hoursLoading: true };
        case 'HOUR_UPDATE_SUCCESS':
            return {...state, hoursLoading: false };
        case 'HOUR_UPDATE_ERROR':
            return {...state, hoursLoading: false };

        case 'HOUR_DELETE':
            return {...state, hoursLoading: true };
        case 'HOUR_DELETE_SUCCESS':
            return {...initialState, hoursLoading: false };
        case 'HOUR_CREATE_ERROR':
            return {...state, hoursLoading: false };
            

        case 'HOURS_DATE_CHANGE':
            return {...state, hoursDate: action.hoursDate }
        case 'HOURS_ABBR_CHANGE':
            return {...state, hoursAbbr: action.hoursAbbr }
        case 'HOURS_CLOSED_CHANGE':
            return {...state, hoursClosed: action.hoursClosed }
        case 'HOURS_START_TIME_CHANGE':
            return {...state, hoursStartTime: action.hoursStartTime }
        case 'HOURS_END_TIME_CHANGE':
            return {...state, hoursEndTime: action.hoursEndTime }

        default:
            return state;
    }
}
import {AppEpic} from "../../react-app-env";
import {ofType} from "redux-observable";
import {catchError, map, mergeMap, switchMap} from "rxjs/operators";
import {authAjax} from "../../common/utils";
import {AjaxResponse} from "rxjs/ajax";
import {of} from "rxjs";
import {
    createHoursError,
    createHoursSuccess, deleteHourError, deleteHourSuccess, getHours,
    getHoursSuccess,
    selectHourError,
    selectHourSuccess, updateHourError, updateHourSuccess
} from "./hours-actions";
import {getBeers, getBeersSuccess, selectBeerError, selectBeerSuccess} from "../beer/beers-actions";




    export const getHoursEpic = action$ =>
        action$.pipe(
            ofType('HOURS_REQUEST'),
            switchMap(action => authAjax.post({
                url: `/.netlify/functions/graph`,
                // method: 'POST',
                // responseType: 'json',
                body: `{
                  hours (where: {special: true}){
                    id
                    date
                    abbr
                    startTime
                    endTime
                    closed
                  }
                }`
            })),
            map((response: AjaxResponse) => getHoursSuccess(response.response.data.hours))
            // .catch(e => Observable.of(getHoursError(e.message)))
        );

    export const selectHourEpic: AppEpic = (action$, store$, deps) =>
        action$.pipe(
            ofType('HOUR_SELECT'),
            switchMap(action => authAjax.post({
                url: `/.netlify/functions/graph`,
                // method: 'POST',
                // responseType: 'json',
                body: `{
                  hours(where: {id: "${action.payload}"}) {
                    id
                    date
                    abbr
                    startTime
                    endTime
                    closed
                  }
                }`
            })),
            map((response: AjaxResponse) => selectHourSuccess(response.response.data.hours[0])),
            catchError(e => of(selectHourError(e.message)))
        );


export const createHoursEpic: AppEpic = (action$, store$, deps) => {
    return action$.pipe(
        ofType('HOURS_CREATE'),
        mergeMap(action => authAjax.post({
            url: `/.netlify/functions/graph`,
            body: `mutation {
                      createHour(
                        data: {
                          date: "${action.payload.date}"
                          special: true
                          ${action.payload.abbr && (`abbr: "${ action.payload.abbr }"`)}
                          ${action.payload.startTime && (`startTime: ${ action.payload.startTime }`)}
                          ${action.payload.endTime && (`endTime: ${ action.payload.endTime }`)}
                          closed: ${ action.payload.closed }
                        }
                      ) {
                        id
                      }
                    }`
        })),
        map((response: AjaxResponse) => {
            console.log(response.response.data.createHour.id);
            return createHoursSuccess(response.response.data.createHour)
        }),
        catchError(e => of(createHoursError(e.message)))
    )};

export const updateHoursEpic: AppEpic = (action$, store$, deps) => {
    return action$.pipe(
        ofType('HOUR_UPDATE'),
        mergeMap(action => authAjax.post({
            url: `/.netlify/functions/graph`,
            body: `mutation {
                      updateHour(
                        data: {
                          special: true
                          ${action.payload.abbr && (`abbr: "${ action.payload.abbr }"`)}
                          ${action.payload.startTime && (`startTime: ${ action.payload.startTime }`)}
                          ${action.payload.endTime && (`endTime: ${ action.payload.endTime }`)}
                          closed: ${ action.payload.closed }
                        }
                        where: {
                            id: "${action.payload.id}"
                        }
                      ) {
                        id
                      }
                    }`
        })),
        map((response: AjaxResponse) => updateHourSuccess(response.response.data.updateHour)),
        catchError(e => of(updateHourError(e.message)))
    )};


export const deleteHoursEpic: AppEpic = (action$, store$, deps) => {
    return action$.pipe(
        ofType('HOUR_DELETE'),
        mergeMap(action => authAjax.post({
            url: `/.netlify/functions/graph`,
            body: `mutation {
                      deleteHour(
                        where: {
                            id: "${action.payload.id}"
                        }
                      ) {
                        id
                      }
                    }`
        })),
        map((response: AjaxResponse) => deleteHourSuccess(response.response.data.deleteHour)),
        catchError(e => of(deleteHourError(e.message)))
    )};



export const createHoursSuccessEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('HOURS_CREATE_SUCCESS'),
        map(() => getHours())
    );

const initialState = {
    hometest: {}
}

export const homeReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'HOMETEST_REQUEST_SUCCESS':
            return {...state, hometest: action.payload };

        default:
            return state;
    }
}
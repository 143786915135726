import {AppEpic} from "../../react-app-env";
import {ajax, AjaxResponse} from 'rxjs/ajax';
import {Observable, of} from "rxjs";
import {switchMap, map, catchError, mergeMap} from 'rxjs/operators';
import {ofType} from "redux-observable";
import {
    createBeerError,
    createBeerSuccess, getBeers,
    getBeersError,
    getBeersSuccess,
    getBeersUpdateSuccess, selectBeerError,
    selectBeerSuccess, updateBeerError, updateBeerSuccess
} from "./beers-actions";
import {authAjax, hasValue} from "../../common/utils";


// export const getBeersEpic: AppEpic = (action$, store, {}) =>
export const getBeersEpic = action$ =>
    action$.pipe(
        ofType('BEERS_REQUEST'),
        switchMap(action => authAjax.post({
            url: `/.netlify/functions/getBeers`,
            // method: 'POST',
            // responseType: 'json',
            body: `{
                "method": "get",
                "payload": ""
            }`
            })),
        map((response: AjaxResponse) => {//getBeersSuccess(response.response)
            const beers = response.response.records.map(beer => (
                    {
                        id: beer.id,
                        name: beer.fields.Name
                    })
                )

                return getBeersSuccess(beers)
            }
            ),
            catchError(e => of(getBeersError(e.message)))
    );

export const selectBeerEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('BEER_SELECT'),
        switchMap(action => authAjax.post({
            url: `/.netlify/functions/getBeer`,
            // method: 'POST',
            // responseType: 'json',
            body: `{
                "route": "Beers/${action.payload}/",
                "method": "get",
                "payload": ""
            }`
        })),
        map((response: AjaxResponse) => selectBeerSuccess({
                abv: response.response.Abv,
                beyondTheDoor: response.response.BeyondTheDoor,
                description: response.response.Description,
                ibu: response.response.Ibu,
                id: response.response.id,
                programEligible: response.response.ProgramEligible,
                name: response.response.Name,
                price: response.response.Price,
                size: response.response.Size,
                style: response.response.Style
            })),
        catchError(e => of(selectBeerError(e.message)))
    );

export const createBeerEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('BEER_CREATE'),
        mergeMap(action => authAjax.post({
            url: `/.netlify/functions/createBeer`,
            // method: 'POST',
            // responseType: 'json',
            body: `{
                      "records": [
                        {
                            "fields": {
                              "Name": "${action.payload.name}",
                              "Style": "${action.payload.style}",
                              ${hasValue(action.payload.abv) && (`"Abv": ${action.payload.abv},`)}
                              ${hasValue(action.payload.ibu) && (`"Ibu": ${action.payload.ibu},`)}
                              "Description": "${action.payload.description}",
                              ${hasValue(action.payload.price) && (`"Price": ${action.payload.price},`)}
                              ${action.payload.size && (`"Size": "${action.payload.size}",`)}
                              ${action.payload.level && (`level: ${action.payload.level},`)}
                              "BeyondTheDoor": ${action.payload.beyondTheDoor}
                            }
                        }
                      ]
                    }`
        })),
        map((response: AjaxResponse) => createBeerSuccess(response.response)),
        catchError(e => of(createBeerError(e.message)))
    );

export const updateBeerEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('BEER_UPDATE'),
        mergeMap(action => authAjax.post({
            url: `/.netlify/functions/updateBeer`,
            // method: 'POST',
            // responseType: 'json',
            body: `{
                      "records": [
                        {
                            "id": "${action.payload.id}",
                            "fields": {
                              "Name": "${action.payload.name}",
                              "Style": "${action.payload.style}",
                              ${hasValue(action.payload.abv) && (`"Abv": ${action.payload.abv},`)}
                              ${hasValue(action.payload.ibu) && (`"Ibu": ${action.payload.ibu},`)}
                              "Description": "${action.payload.description}",
                              ${hasValue(action.payload.price) && (`"Price": ${action.payload.price},`)}
                              ${action.payload.size && (`"Size": "${action.payload.size}",`)}
                              ${action.payload.level && (`level: ${action.payload.level},`)}
                              "BeyondTheDoor": ${action.payload.beyondTheDoor}
                            }
                        }
                      ]
                    }`
        })),
        map((response: AjaxResponse) => updateBeerSuccess(response.response)),
        catchError(e => of(updateBeerError(e.message)))
    );

export const createBeerSuccessEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('BEER_CREATE_SUCCESS'),
        mergeMap(() => [
            getBeers()
        ])
        // No Error
        // catchError(e => of(updateBeerError(e.response.errors[0].message)))
    );


import * as React from "react";
import {connect, Provider} from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import {IAppState} from "../../react-app-env";
import CircleLoader from 'react-spinners/CircleLoader';
import styled from "styled-components";


const LoaderStyles = styled.div`
    z-index: 1000;
    opacity: .99
    
    div {
        position: absolute;
        top: 75px;
        left: 0px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
    }
    
    @media print
    {    
        display: none !important;
    }
    
`;

interface IAreaLoaderProps {
    tapsLoading: boolean;
    beersLoading: boolean;
    hoursLoading: boolean;
    eventsLoading: boolean;

}

export class AreaLoader extends React.Component<IAreaLoaderProps> {

    render() {
        return (
            <LoaderStyles>
                <CircleLoader
                    sizeUnit={"px"}
                    size={75}
                    color={'#eebd5a'}
                    loading={this.props.tapsLoading || this.props.beersLoading || this.props.hoursLoading || this.props.eventsLoading }
                />
            </LoaderStyles>
        );
    }
}

export const mapStateToProps = (state: IAppState) => ({
    tapsLoading: state.taps.tapsLoading,
    beersLoading: state.beer.beersLoading,
    hoursLoading: state.hours.hoursLoading,
    eventsLoading: state.event.eventsLoading
})

export const ConnectedAreaLoader = connect(
    mapStateToProps,
    {

    }
)(AreaLoader);


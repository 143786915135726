import {AppEpic, IAction} from "../../react-app-env";
import {ajax, AjaxResponse} from 'rxjs/ajax';
import {Observable, of} from "rxjs";
import {switchMap, map, mergeMap, catchError} from 'rxjs/operators';
import {ofType} from "redux-observable";
import {
    unconnectTappedBeerSuccess,
    getTapsError,
    getTapsSuccess,
    connectTappedBeerSuccess,
    getTaps,
    netlifyBuildSuccess,
} from "./taps-actions";
import {authAjax} from "../../common/utils";
import {getBeersError, getBeersSuccess} from "../beer/beers-actions";

export const getTapsEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('TAPS_REQUEST'),
        switchMap(action => authAjax.post({
            url: `/.netlify/functions/airtable`,
            // method: 'POST',
            // responseType: 'json',
            body: `{
                "route": "Taps",
                "method": "get",
                "payload": ""
            }`
        })),
        map((response: AjaxResponse) => {//getBeersSuccess(response.response)
            // const taps = response.response.records.filter(tap => tap.fields.Tap).sort((a, b) => (a.fields.Tap[0] > b.fields.Tap[0]) ? 1 : -1).map(tap => (
            //     {
            //         id: tap.fields.Taps[0],
            //         tapnumber: tap.fields.Tap[0],
            //         tappedbeer: {
            //             id: tap.id,
            //             name: tap.fields.Name,
            //             description: tap.fields.Description,
            //             style: tap.fields.Style,
            //             price: tap.fields.Price,
            //             size: tap.fields.Size,
            //             abv: tap.fields.Abv,
            //             ibu: tap.fields.Ibu,
            //             beyondTheDoor: tap.fields.BeyondTheDoor
            //         }
            //     }
            // ));
                const taps = response.response.records.sort((a, b) => (a.fields.TapNumber > b.fields.TapNumber) ? 1 : -1).map(tap => (
                    tap.fields.TappedBeer
                    ? {
                        id: tap.id,
                        tapnumber: tap.fields.TapNumber,
                        tappedbeer: {
                            id: tap.fields.TappedBeer[0],
                            name: tap.fields.Name[0],
                            description: tap.fields.Description[0],
                            style: tap.fields.Style[0],
                            price: tap.fields.Price[0],
                            size: tap.fields.Size[0],
                            abv: tap.fields.Abv[0],
                            ibu: tap.fields.Ibu[0],
                            beyondTheDoor: tap.fields.BeyondTheDoor[0],
                            programEligible: tap.fields.ProgramEligible[0]
                        }
                    }
                    : {
                            id: tap.id,
                            tapnumber: tap.fields.TapNumber,
                        }
                ));

                return getTapsSuccess(taps)
            }
        ),
        catchError(e => of(getTapsError(e.message)))
    );

export const unconnectTappedBeerEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('UNCONNECT_TAPPED_BEER'),
        switchMap(action => authAjax.post({
            url: `/.netlify/functions/airtable`,
            // method: 'POST',
            // responseType: 'json',
            body: `{
                "route": "Taps",
                "method": "patch",
                "payload": {
                  "records": [
                    {
                      "id": "${action.payload}",
                      "fields": {
                        "TappedBeer": []
                      }
                    }
                  ]
                }
            }`
        })),
        // switchMap(action => authAjax.post({
            //     url: `/.netlify/functions/graph`,
            //     // method: 'POST',
            //     // responseType: 'json',
            //     body: `mutation {
            //         updateTap(
            //         data: {
            //           tappedbeer: {
            //             disconnect: true
            //           }
            //         }
            //         where: {
            //           id: "${action.payload}"
            //         }
            //       ) {
            //         id
            //       }
            //     }`
            // })),
        map((response: AjaxResponse) => unconnectTappedBeerSuccess(response.response.data))
        // catch(e => Observable.of(getHomeTestError(e.message)))
    );

export const connectTappedBeerEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('CONNECT_TAPPED_BEER'),
        switchMap(action => authAjax.post({
            url: `/.netlify/functions/airtable`,
            // method: 'POST',
            // responseType: 'json',
            body: `{
                "route": "Taps",
                "method": "patch",
                "payload": {
                  "records": [
                    {
                      "id": "${action.payload.tapId}",
                      "fields": {
                        "TappedBeer": ["${action.payload.beerId}"]
                      }
                    }
                  ]
                }
            }`
        })),
        // switchMap(action => authAjax.post({
        //         url: `/.netlify/functions/graph`,
        //         // method: 'POST',
        //         // responseType: 'json',
        //         body: `mutation {
        //             updateTap(
        //             data: {
        //               tappedbeer: {
        //                 connect: {
        //                   id: "${action.payload.beerId}"
        //                 }
        //               }
        //             }
        //             where: {
        //               id: "${action.payload.tapId}"
        //             }
        //           ) {
        //             id
        //           }
        //         }`
        //     })),
        map((response: AjaxResponse) => connectTappedBeerSuccess(response.response.data))
        // catch(e => Observable.of(getHomeTestError(e.message)))
    );

export const connectTappedBeerSuccessEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('CONNECT_TAPPED_BEER_SUCCESS'),
        map((response) => getTaps())
        // catch(e => Observable.of(getHomeTestError(e.message)))
    );

export const unconnectTappedBeerSuccessEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('UNCONNECT_TAPPED_BEER_SUCCESS'),
        map((response) => getTaps())
        // catch(e => Observable.of(getHomeTestError(e.message)))
    );

export const netlifytBuildEpic: AppEpic = (action$, store$, deps) =>
    action$.pipe(
        ofType('NETLIFY_BUILD_REQUEST'),
        switchMap(action => authAjax.post({
            url: `/.netlify/functions/netlifyBuild`
        })),
        map((response: AjaxResponse) => netlifyBuildSuccess(response.response))
        // catch(e => Observable.of(getHomeTestError(e.message)))
    );
import React, { Component } from 'react';
import './App.css';
import {connect, Provider} from 'react-redux';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import {ConnectedNavbar} from "./areas/navbar/Navbar";
import {RouterConnectedBeer} from "./areas/beer/Beer";
import {RouterConnectedAreaTaps} from "./areas/taps/Taps";
import {RouterConnectedAreaMenu} from "./areas/menu/Menu";
import netlifyIdentity from "netlify-identity-widget";
import {AreaHome, ConnectedAreaHome} from "./areas/home/Home";
import {getUserSuccess, putUserLogout} from "./common/user/user-actions";
import {IAppState} from "./react-app-env";
import {ProtectedRoute} from "./common/components/ProtectedRoute";
import styled from "styled-components";
import {ConnectedAreaLoader} from "./areas/loader/Loader";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {withTracker} from "./common/components/withTracker";
import {RouterConnectedHours} from "./areas/hours/Hours";
import {RouterConnectedEvent} from "./areas/event/Event";

interface IMainProps {
    user: any;
    isAuth: boolean;
    onLogin: (user) => { type: 'USER_REQUEST_SUCCESS', payload };
    onLogout: (user) => { type: 'USER_LOGOUT' };
}

class Main extends React.Component<IMainProps> {

    componentDidMount() {
        netlifyIdentity.init();

        // TODO: Reset user here?

        netlifyIdentity.currentUser() //&& this.props.user
            ? this.props.onLogin(netlifyIdentity.currentUser())
            : null;

        netlifyIdentity.on("login", (user) => this.props.onLogin(netlifyIdentity.currentUser()));
        netlifyIdentity.on("logout", (user) => this.props.onLogout(netlifyIdentity.currentUser()));
    }
    handleIdentity = (e) => {
        e.preventDefault();

        if(netlifyIdentity.currentUser()) {
            console.log(netlifyIdentity.currentUser());
        } else {
            netlifyIdentity.open();
        }
    }

    render() {
        return (
            <Router>
                <div>
                    <ConnectedNavbar />
                    <div className="container-fluid p-0">
                        <ToastContainer />
                        <Switch>
                            <Route exact path="/" component={withTracker(ConnectedAreaHome)}/>
                            <ProtectedRoute isAllowed={this.props.isAuth} exact path="/taps" component={withTracker(RouterConnectedAreaTaps)}/>
                            <ProtectedRoute isAllowed={this.props.isAuth} exact path="/beers" component={withTracker(RouterConnectedBeer)}/>
                            <ProtectedRoute isAllowed={this.props.isAuth} exact path="/menu" component={withTracker(RouterConnectedAreaMenu)}/>
                            <ProtectedRoute isAllowed={this.props.isAuth} exact path="/events" component={withTracker(RouterConnectedEvent)}/>
                            <ProtectedRoute isAllowed={this.props.isAuth} exact path="/hours" component={withTracker(RouterConnectedHours)}/>
                        </Switch>
                        <ConnectedAreaLoader />
                    </div>
                </div>
            </Router>

        );
    }
}

export const mapStateToProps = (state: IAppState) => ({
    user: state.user.user,
    isAuth: state.user.isAuth
})

export const ConnectedMain = connect(
    mapStateToProps,
    {
        onLogin: (user) => getUserSuccess(user),
        onLogout: (user) => putUserLogout()
    }
)(Main);



import * as React from "react";
import {connect} from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {IAppState} from "../../react-app-env";
import {nameMatch} from "../../common/utils";
import {IGraphEvent} from "../../hf-admin-types";
import {
    eventDescriptionChange,
    eventDisplayDateChange,
    eventEndTimeChange, eventLongDescriptionChange, eventMarqueeChange,
    eventNameChange,
    eventStartTimeChange, eventTypeChange, getEvents
} from "./event-actions";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {TapsRow} from "../taps/TapsRow";
import {EventTile} from "./EventTile";

import {IoIosBuild} from "react-icons/io";

const EventStyles = styled.div`
    padding-top: 15px;       
    padding-left: 15px;
    padding-right: 15px;
    
    option[value=""][disabled] {
        display: none;
    }
`

interface IEventProps {
    eventId: string;
    eventName: string;
    eventStartTime: Date;
    eventEndTime: Date;
    eventDisplayDate: string;
    eventDescription: string;
    eventLongDescription: string;
    eventType: string;
    eventMarquee: boolean;

    events: IGraphEvent[];

    // beers: IGraphEvent[];
    getEvents: () => { type: string };
    // selectEvent: (payload) => { type: string, payload };
    // clearEvent: () => { type: string };
    // // getEvents: () => { type: 'BEERS_REQUEST' };
    //
    // createEvent: (payload) => { type: string, payload };
    // updateEvent: (payload) => { type: string, payload };
    //

    onEventNameChange: (name) => { type: string, name};
    onEventStartTimeChange: (startTime) => { type: string, startTime};
    onEventEndTimeChange: (endTime) => { type: string, endTime};
    onEventDisplayDateChange: (displayDate) => { type: string, displayDate};
    onEventDescriptionChange: (description) => { type: string, description};
    onEventLongDescriptionChange: (longDescription) => { type: string, longDescription};
    onEventTypeChange: (eventType) => { type: string, eventType};
    onEventMarqueeChange: (marquee) => { type: string, marquee};


}

export class AreaEvent extends React.Component<IEventProps> {

    constructor(props) {
        super(props);

        this.state = {
            modal: false
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState['modal']
        }));
    }

    componentDidMount() {
        this.props.getEvents();
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const params = {
            name: this.props.eventName,
            startTime: this.props.eventStartTime,
            endTime: this.props.eventEndTime,
            displayDate: this.props.eventDisplayDate,
            description: this.props.eventDescription,
            longDescription: this.props.eventLongDescription,
            type: this.props.eventType,
            marquee: this.props.eventMarquee

        };

        // this.props.createEvent(params);
        console.log('Create Event', params);
    }

    handleModify = (e) => {
        e.preventDefault();

        const params = {
            id: this.props.eventId,
            name: this.props.eventName,
            startTime: this.props.eventStartTime,
            endTime: this.props.eventEndTime,
            displayDate: this.props.eventDisplayDate,
            description: this.props.eventDescription,
            longDescription: this.props.eventLongDescription,
            type: this.props.eventType,
            marquee: this.props.eventMarquee
        };

        // this.props.updateEvent(params);
        console.log('Modify Event', params);
    }

    verifyDelete = (e) => {
        e.preventDefault();

        this.setState({ modal: true });
    }

    handleDelete = (e) => {
        e.preventDefault();

        const params = {
            id: this.props.eventId
        };

        this.toggle();

        // this.props.deleteEvent(params);

        console.log('Delete Event', params);
    }

    handleEventNameChange = (e) => {
        const name = e.target.value;

        this.props.onEventNameChange(name);
    }

    handleEventStartTimeChange = (e) => {
        const startTime = e;

        this.props.onEventStartTimeChange(startTime);
    }

    handleEventEndTimeChange = (e) => {
        const endTime = e;

        this.props.onEventEndTimeChange(endTime);
    }

    handleEventDisplayDateChange = (e) => {
        const displayDate = e.target.value;

        this.props.onEventDisplayDateChange(displayDate);
    }

    handleEventDescriptionChange = (e) => {
        const description = e.target.value;

        this.props.onEventDescriptionChange(description);
    }

    handleEventLongDescriptionChange = (e) => {
        const longDescription = e.target.value;

        this.props.onEventLongDescriptionChange(longDescription);
    }

    handleEventTypeChange = (e) => {
        const eventType = e.target.value;

        this.props.onEventTypeChange(eventType);
    }

    handleEventMarqueeChange = (e) => {
        const marquee = e.target.checked;

        this.props.onEventMarqueeChange(marquee);
    }

    render() {
        return (
            <EventStyles>
                {/* TODO: Delete Modal Component */}
                <Modal isOpen={this.state['modal']} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Delete Special Event</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete the special event on {this.props.eventStartTime && this.props.eventStartTime.toLocaleDateString("en-US", { month: 'short', day: 'numeric' })} ?
                    </ModalBody>
                    <ModalFooter>

                        <Button color="primary" onClick={this.toggle}>Cancel</Button>{' '}
                        <Button color="danger" onClick={this.handleDelete}>Yes, Delete</Button>
                    </ModalFooter>
                </Modal>


                <div className="d-flex align-items-center justify-content-between">
                    <div><h1>Events</h1></div>
                    <div><IoIosBuild className="h4" /></div>
                </div>

                {false &&
                    <form>

                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <label htmlFor="eventName">Name</label>
                                    <input id="eventName"
                                           className="form-control"
                                           autoComplete="off"
                                           onChange={this.handleEventNameChange}
                                           value={this.props.eventName}/>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="eventType">Type</label>
                                    <div className="input-group">

                                        {/* TODO: API for Event Types */}
                                        <select id="eventType"
                                                className="form-control"
                                                onChange={this.handleEventTypeChange}
                                                value={this.props.eventType}>
                                            <option value="" disabled></option>
                                            <option>Taproom Release</option>
                                            <option>Special Taproom Event</option>
                                            <option>Weekly Event</option>
                                            <option>Tap Takeover</option>
                                            <option>Beer Festival</option>
                                            <option>Pouring Event</option>
                                            <option>Food Truck</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="eventStartTime">Start Date</label>
                                    <br/>
                                    <DatePicker
                                        showTimeSelect
                                        dateFormat="Pp"
                                        selected={this.props.eventStartTime}
                                        onChange={this.handleEventStartTimeChange}
                                        className="form-control"
                                        id="eventStartTime"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="eventEndTime">End Date</label>
                                    <br/>
                                    <DatePicker
                                        showTimeSelect
                                        dateFormat="Pp"
                                        selected={this.props.eventEndTime}
                                        onChange={this.handleEventEndTimeChange}
                                        className="form-control"
                                        id="eventEndTime"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="eventMarquee"
                                           checked={this.props.eventMarquee}
                                           onChange={this.handleEventMarqueeChange}
                                    />
                                    <label className="form-check-label" htmlFor="eventMarquee">Marquee</label>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="eventDescription">
                                        Description
                                        {/*(*/}
                                        {/*<span className={this.props.eventDescription.length > 180 ? 'bg-danger text-white' : ''}>{this.props.eventDescription.length}</span>*/}
                                        {/*)*/}
                                    </label>
                                    <textarea id="eventDescription"
                                              rows={3}
                                              className="form-control"
                                              onChange={this.handleEventDescriptionChange}
                                              value={this.props.eventDescription}>
                                    </textarea>
                                </div>

                            </div>
                        </div>

                        <div className="row">

                            <div className="btn-toolbar pt-3" role="toolbar">
                                {!this.props.eventId &&
                                this.props.eventStartTime && this.props.eventEndTime && this.props.eventName && this.props.eventType &&
                                <div className="col">
                                    <button onClick={this.handleSubmit} className="btn btn-primary">Submit</button>
                                </div>
                                }

                                {this.props.eventId &&
                                this.props.eventStartTime && this.props.eventEndTime && this.props.eventName && this.props.eventType &&
                                <div>
                                    <div className="col">
                                        <button onClick={this.handleModify} className="btn btn-warning">Modify</button>
                                    </div>
                                    <div className="col">
                                        <button onClick={this.verifyDelete} className="btn btn-danger">Delete</button>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </form>
                }

                <div>
                    {this.props.events.slice(0, 10).map((event: any) => (
                        <EventTile event={event.values}
                                   theme={true}
                                 // onConnect={this.handleConnect}
                                 // onUnconnect={this.handleUnconnect}
                                 // beers={this.props.beers}
                                 key={event.values[0].id} />
                    ))}
                </div>
            </EventStyles>

        );
    }
}

export const mapStateToProps = (state: IAppState) => ({
    eventId: state.event.eventId,
    eventName: state.event.eventName,
    eventStartTime: state.event.eventStartTime,
    eventEndTime: state.event.eventEndTime,
    eventDisplayDate: state.event.eventDisplayDate,
    eventDescription: state.event.eventDescription,
    eventLongDescription: state.event.eventLongDescription,
    eventType: state.event.eventType,
    eventMarquee: state.event.eventMarquee,



    events: state.event.events
    // beers: state.beer.beers
})

export const ConnectedEvent = connect(
    mapStateToProps,
    {
        getEvents: () => getEvents(),
        // selectEvent: (id) => selectEvent(id),
        // clearEvent: () => clearEvent(),
        // createEvent: (beer) => createEvent(beer),
        // updateEvent: (beer) => updateEvent(beer),
        onEventNameChange: (eventName) => eventNameChange(eventName),
        onEventStartTimeChange: (eventStartTime) => eventStartTimeChange(eventStartTime),
        onEventEndTimeChange: (eventEndTime) => eventEndTimeChange(eventEndTime),
        onEventDisplayDateChange: (eventDisplayDate) => eventDisplayDateChange(eventDisplayDate),
        onEventDescriptionChange: (eventDescription) => eventDescriptionChange(eventDescription),
        onEventLongDescriptionChange: (eventLongDescription) => eventLongDescriptionChange(eventLongDescription),
        onEventTypeChange: (eventType) => eventTypeChange(eventType),
        onEventMarqueeChange: (eventMarquee) => eventMarqueeChange(eventMarquee),
    }
)(AreaEvent);

export const RouterConnectedEvent = withRouter(ConnectedEvent);


import {IAction} from "../../react-app-env";
import {ITapBeer} from "../../hf-admin-types";

export type GetTaps = IAction<'TAPS_REQUEST'>;
export const getTaps = () => ({ type: 'TAPS_REQUEST' });

export type GetTapsSuccess = IAction<'TAPS_REQUEST_SUCCESS', { payload: Object[] }>;
export const getTapsSuccess = (taps: Object) => ({ type: 'TAPS_REQUEST_SUCCESS', payload: taps });

export type GetTapsError = IAction<'TAPS_REQUEST_ERROR', { payload: Error }>;
export const getTapsError = (error: Error) => ({ type: 'TAPS_REQUEST_ERROR', payload: { error } });

export type ConnectTappedBeer = IAction<'CONNECT_TAPPED_BEER', { payload: ITapBeer }>;
export const connectTappedBeer = (tapBeer: ITapBeer) => ({ type: 'CONNECT_TAPPED_BEER', payload: tapBeer });

export type ConnectTappedBeerSuccess = IAction<'CONNECT_TAPPED_BEER_SUCCESS', { payload: number }>;
export const connectTappedBeerSuccess = (tapnumber: number) => ({ type: 'CONNECT_TAPPED_BEER_SUCCESS', payload: tapnumber });

export type UnconnectTappedBeer = IAction<'UNCONNECT_TAPPED_BEER', { payload: string }>;
export const unconnectTappedBeer = (id: string) => ({ type: 'UNCONNECT_TAPPED_BEER', payload: id });

export type UnconnectTappedBeerSuccess = IAction<'UNCONNECT_TAPPED_BEER_SUCCESS', { payload: number }>;
export const unconnectTappedBeerSuccess = (tapnumber: number) => ({ type: 'UNCONNECT_TAPPED_BEER_SUCCESS', payload: tapnumber });






export type NetlifyBuild = IAction<'NETLIFY_BUILD_REQUEST'>;
export const netlifyBuild = () => ({ type: 'NETLIFY_BUILD_REQUEST' });

export type NetlifyBuildSuccess = IAction<'NETLIFY_BUILD_SUCCESS', { payload: {} }>;
export const netlifyBuildSuccess = (response: {}) => ({ type: 'NETLIFY_BUILD_SUCCESS', payload: {} });




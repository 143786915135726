import {IAction} from "../../react-app-env";


export type GetUser = IAction<'USER_REQUEST'>;
export const getUser = () => ({ type: 'USER_REQUEST' });

export type GetUserSuccess = IAction<'USER_REQUEST_SUCCESS', { payload: any }>;
export const getUserSuccess = (user: any) => ({ type: 'USER_REQUEST_SUCCESS', payload: user });

export type GetUserError = IAction<'USER_REQUEST_ERROR', { payload: Error }>;
export const getUserError = (error: Error) => ({ type: 'USER_REQUEST_ERROR', payload: { error } });

export type PutUserLogout = IAction<'USER_LOGOUT'>;
export const putUserLogout = () => ({ type: 'USER_LOGOUT' });
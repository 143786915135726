import * as React from "react";
import styled from "styled-components";
import {IAppState} from "../../react-app-env";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {getHomeTest} from "./home-actions";
import { Link } from 'react-router-dom';

const HomeStyles = styled.div`
    padding-top: 15px;       
    padding-left: 15px;
    padding-right: 15px;
`

interface IAreaHomeProps {
    isAuth: boolean;
    hometest: any;

    getHomeTest: () => { type: 'HOMETEST_REQUEST' };
}

export class AreaHome extends React.Component<IAreaHomeProps> {

    componentDidMount() {
        // this.props.getHomeTest();
    }


    render() {
        return (
            <HomeStyles>
                <h1>HeadFlyer Admin Tool</h1>

                <ul>
                    <li>Modify the <Link to="/taps">Tap List</Link></li>
                    <li>Modify <Link to="/beers">Beers</Link></li>
                    <li>View the current <Link to="/menu">Menu</Link></li>
                    <li>View <Link to="/events">Events</Link></li>
                    <li>Modify <Link to="/hours">Special Hours</Link></li>
                </ul>

            </HomeStyles>
        );
    }
}

export const mapStateToProps = (state: IAppState) => ({
    isAuth: state.user.isAuth,
    home: state.home.hometest
})

export const ConnectedAreaHome = connect(
    mapStateToProps,
    {
        getHomeTest: () => getHomeTest()
    }
)(AreaHome);

// export const RouterConnectedAreaHome = withRouter(ConnectedAreaHome);


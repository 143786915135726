import {IAction} from "../../react-app-env";
import {IGraphEvent} from "../../hf-admin-types";
import {toast} from "react-toastify";


export type EventIdChange = IAction<'EVENT_ID_CHANGE', { id: string }>;
export const eventIdChange = (id: string) => ({ type: 'EVENT_ID_CHANGE', id});

export type EventNameChange = IAction<'EVENT_NAME_CHANGE', { name: string }>;
export const eventNameChange = (name: string) => ({ type: 'EVENT_NAME_CHANGE', name});

export type EventStartTimeChange = IAction<'EVENT_START_TIME_CHANGE', { startTime: string }>;
export const eventStartTimeChange = (startTime: string) => ({ type: 'EVENT_START_TIME_CHANGE', startTime});

export type EventEndTimeChange = IAction<'EVENT_END_TIME_CHANGE', { endTime: string }>;
export const eventEndTimeChange = (endTime: string) => ({ type: 'EVENT_END_TIME_CHANGE', endTime});

export type EventDisplayDateChange = IAction<'EVENT_DISPLAY_DATE_CHANGE', { displayDate: string }>;
export const eventDisplayDateChange = (displayDate: string) => ({ type: 'EVENT_DISPLAY_DATE_CHANGE', displayDate});

export type EventDescriptionChange = IAction<'EVENT_DESCRIPTION_CHANGE', { description: string }>;
export const eventDescriptionChange = (description: string) => ({ type: 'EVENT_DESCRIPTION_CHANGE', description});

export type EventLongDescriptionChange = IAction<'EVENT_LONG_DESCRIPTION_CHANGE', { longDescription: string }>;
export const eventLongDescriptionChange = (longDescription: string) => ({ type: 'EVENT_LONG_DESCRIPTION_CHANGE', longDescription});

export type EventTypeChange = IAction<'EVENT_TYPE_CHANGE', { eventType: string }>;
export const eventTypeChange = (eventType: string) => ({ type: 'EVENT_TYPE_CHANGE', eventType});

export type EventMarqueeChange = IAction<'EVENT_MARQUEE_CHANGE', { marquee: boolean }>;
export const eventMarqueeChange = (marquee: boolean) => ({ type: 'EVENT_MARQUEE_CHANGE', marquee});



export type GetEvents = IAction<'EVENTS_REQUEST'>;
export const getEvents = () => ({ type: 'EVENTS_REQUEST' });

export type GetEventsUpdateSuccess = IAction<'EVENTS_REQUEST_UPDATE_SUCCESS'>;
export const getEventsUpdateSuccess = () => ({ type: 'EVENTS_REQUEST_UPDATE_SUCCESS' });

export type GetEventsSuccess = IAction<'EVENTS_REQUEST_SUCCESS', { payload: Object[] }>;
export const getEventsSuccess = (events: Object) => ({ type: 'EVENTS_REQUEST_SUCCESS', payload: events });
import * as React from "react";
import {Redirect, Route} from "react-router";

export const ProtectedRoute
    = ({ isAllowed, ...props }) => {

    return (

        isAllowed //.hasOwnProperty('access_token')
            ? <Route {...props}/>
            : <Redirect to="/"/>

    );

}





import React, { Component } from 'react';
import './App.css';
import {Provider} from 'react-redux';
import configureStore from './store';
import {ConnectedMain} from "./Main";
import ReactGA from 'react-ga';

let store = configureStore();

export class App extends Component {

  render() {

    ReactGA.initialize('UA-77113964-2');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <Provider store={store}>
            <ConnectedMain />
        </Provider>

    );
  }
}

export default App;


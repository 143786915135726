import * as React from "react";
import {connect} from "react-redux";
import styled from 'styled-components';
import {withRouter} from "react-router-dom";
import {IAppState} from "../../react-app-env";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
    clearHour,
    createHours, deleteHour, getHours,
    hoursAbbrChange,
    hoursClosedChange,
    hoursDateChange,
    hoursEndTimeChange,
    hoursStartTimeChange, selectHour, updateHour
} from "./hours-actions";
import {nameMatch} from "../../common/utils";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";


const HoursStyles = styled.div`
        padding-top: 15px;       
    padding-left: 15px;
    padding-right: 15px;
    
    `


interface IHoursProps {
    hoursId: string;
    hoursAbbr: string;
    hoursDate: Date;
    hoursStartTime: number;
    hoursEndTime: number;
    hoursClosed: boolean;

    toggle: any;

    hours: [];
    getHours: () => { type: string };
    selectHours: (payload) => { type: string, payload };
    clearHours: () => { type: string };
    hoursLoading: boolean;
    hoursLoaded: boolean;

    createHours: (payload) => { type: string, payload };
    updateHours: (payload) => { type: string, payload };
    deleteHours: (payload) => { type: string, payload };

    onHoursDateChange: (hoursDate) => { type: string, hoursDate };
    onHoursAbbrChange: (hoursAbbr) => { type: string, hoursAbbr};
    onHoursStartTimeChange: (hoursStartTime) => { type: string, hoursStartTime};
    onHoursEndTimeChange: (hoursEndTime) => { type: string, hoursEndTime};
    onHoursClosedChange: (hoursClosed) => { type: string, hoursClosed};
}

export class AreaHours extends React.Component<IHoursProps> {

    constructor(props) {
        super(props);

        this.state = {
            modal: false
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState['modal']
        }));
    }

    componentDidMount() {
        !this.props.hoursLoaded && this.props.getHours();
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const params = {
            date: this.props.hoursDate.toISOString(),
            abbr: this.props.hoursAbbr,
            startTime: this.props.hoursStartTime,
            endTime: this.props.hoursEndTime,
            closed: this.props.hoursClosed
        };

        this.props.createHours(params);
    }

    handleModify = (e) => {
        e.preventDefault();

        const params = {
            id: this.props.hoursId,
            date: this.props.hoursDate.toISOString(),
            abbr: this.props.hoursAbbr,
            startTime: this.props.hoursStartTime,
            endTime: this.props.hoursEndTime,
            closed: this.props.hoursClosed
        };

        this.props.updateHours(params);
    }

    verifyDelete = (e) => {
        e.preventDefault();

        this.setState({ modal: true });
    }

    handleDelete = (e) => {
        e.preventDefault();

        const params = {
            id: this.props.hoursId
        };

        this.toggle();

        this.props.deleteHours(params);
    }

    handleDateChange = (e) => {
        let hoursDate = '';

        if(e instanceof Date) {
            hoursDate = e.toISOString();
        }

        this.props.onHoursDateChange(e)

        const match = nameMatch(this.props.hours, hoursDate, 'date');

        if(match) {
            this.props.selectHours(match.id);
        } else if(this.props.hoursId) {
            this.props.clearHours();
        }
    }

    handleAbbrChange = (e) => {
        const abbr = e.target.value;

        this.props.onHoursAbbrChange(abbr);
    }

    handleStartTimeChange = (e) => {
        const time = e.target.value;

        this.props.onHoursStartTimeChange(time);
    }

    handleEndTimeChange = (e) => {
        const time = e.target.value;

        this.props.onHoursEndTimeChange(time);
    }

    handleClosedChange = (e) => {
        const closed = e.target.checked;

        this.props.onHoursClosedChange(closed);
    }

    displayTime = (time) => {
        if(time==24) {
            return '12A';
        } else if(time==12) {
            return '12P';
        } else if(time<12) {
            return time + 'A';
        } else if(time>12) {
            return (time - 12) + 'P';
        }
    }

    render() {

        return (
            <HoursStyles>
                {/* TODO: Delete Modal Component */}
                <Modal isOpen={this.state['modal']} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Delete Special Hours</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete the special hours on {this.props.hoursDate && this.props.hoursDate.toLocaleDateString("en-US", { month: 'short', day: 'numeric' })} ?
                    </ModalBody>
                    <ModalFooter>

                        <Button color="primary" onClick={this.toggle}>Cancel</Button>{' '}
                        <Button color="danger" onClick={this.handleDelete}>Yes, Delete</Button>
                    </ModalFooter>
                </Modal>

                <h1>Special Taproom Hours</h1>

                <form>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="hoursDate">Date</label>
                                <br />
                                <DatePicker
                                    selected={this.props.hoursDate}
                                    onChange={this.handleDateChange}
                                    className="form-control"
                                    id="hoursDate"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="hoursStartTime">Start Time (24h)</label>
                                <input id="hoursStartTime"
                                       type="number"
                                       min="0" max="24"
                                       className="form-control"
                                       onChange={this.handleStartTimeChange}
                                       value={this.props.hoursStartTime}
                                       autoComplete="off" />
                            </div>
                        </div>

                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="hoursEndTime">End Time (24h)</label>
                                <input id="hoursEndTime"
                                       type="number"
                                       min="0" max="24"
                                       className="form-control"
                                       onChange={this.handleEndTimeChange}
                                       value={this.props.hoursEndTime}
                                       autoComplete="off" />
                            </div>
                        </div>
                    </div>


                    <div className="form-group">
                        <label htmlFor="beerStyle">Desc</label>
                        <input id="beerStyle"
                               className="form-control"
                               onChange={this.handleAbbrChange}
                               value={this.props.hoursAbbr}
                               autoComplete="off" />
                    </div>

                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="beerBeyondTheDoor" checked={this.props.hoursClosed}
                               onChange={this.handleClosedChange}
                        />
                        <label className="form-check-label" htmlFor="beerBeyondTheDoor">Closed</label>
                    </div>

                    <div className="btn-toolbar pt-3" role="toolbar">
                        {!this.props.hoursId && this.props.hoursDate &&
                        (this.props.hoursClosed || (this.props.hoursStartTime && this.props.hoursEndTime)) &&
                            <button onClick={this.handleSubmit} className="btn btn-primary">Submit</button>
                        }
                        {this.props.hoursId && this.props.hoursDate &&
                        (this.props.hoursClosed || (this.props.hoursStartTime && this.props.hoursEndTime)) &&
                            <div>
                            <button onClick={this.handleModify} className="btn btn-warning">Modify</button>{' '}
                            <button onClick={this.verifyDelete} className="btn btn-danger">Delete</button>
                            </div>
                        }
                    </div>
                </form>


                <div className="text-center">
                    <h2>Current Special Hours</h2>

                    {this.props.hoursDate &&
                    <h3>
                        {this.props.hoursClosed ? this.props.hoursAbbr ? 'Closed ' : 'Taproom Closed' : ''}
                        {this.props.hoursAbbr || (this.props.hoursClosed ? '' : 'Special Taproom Hours')}
                        {'\xa0\xa0\xa0'}
                        {(this.props.hoursDate instanceof Date) ? this.props.hoursDate.toLocaleDateString("en-us", {
                            month: 'short',
                            day: 'numeric'
                        }) : null}
                        {'\xa0\xa0\xa0'}
                        {!this.props.hoursClosed && (this.displayTime(this.props.hoursStartTime) + ' - ' + this.displayTime(this.props.hoursEndTime))}
                    </h3>
                    }
                    <h3>
                        {this.props.hours.filter((hour: any) => (hour.date >= new Date().toJSON() &&
                                (hour.date != (this.props.hoursDate ? this.props.hoursDate.toJSON() : 'NOMATCH')))
                        )
                            .map((hour: any) => (
                                <div key={`hours-display-${hour.id}`}>
                                    {hour.closed ? hour.abbr ? 'Closed ' : 'Taproom Closed' : ''}
                                    {hour.abbr || (hour.closed ? '' : 'Special Taproom Hours')}
                                    &nbsp;&nbsp;&nbsp;
                                    {hour.date ? new Date(hour.date).toLocaleDateString("en-us", { month: 'short', day: 'numeric' }) : null}
                                    &nbsp;&nbsp;&nbsp;
                                    {!hour.closed && (this.displayTime(hour.startTime) + ' - ' + this.displayTime(hour.endTime))}
                                </div>
                        ))}
                    </h3>
                </div>
            </HoursStyles>
        )

    }
}

export const mapStateToProps = (state: IAppState) => ({
    hoursId: state.hours.hoursId,
    hoursDate: state.hours.hoursDate,
    hoursAbbr: state.hours.hoursAbbr,
    hoursStartTime: state.hours.hoursStartTime,
    hoursEndTime: state.hours.hoursEndTime,
    hoursClosed: state.hours.hoursClosed,
    hoursLoading: state.hours.hoursLoading,
    hoursLoaded: state.hours.hoursLoaded,
    hours: state.hours.hours
})

export const ConnectedHours = connect(
    mapStateToProps,
    {
        getHours: () => getHours(),
        selectHours: (id) => selectHour(id),
        clearHours: () => clearHour(),
        createHours: (hour) => createHours(hour),
        updateHours: (hour) => updateHour(hour),
        deleteHours: (hour) => deleteHour(hour),

        onHoursDateChange: (hoursDate) => hoursDateChange(hoursDate),
        onHoursAbbrChange: (hoursAbbr) => hoursAbbrChange(hoursAbbr),
        onHoursClosedChange: (hoursClosed) => hoursClosedChange(hoursClosed),
        onHoursStartTimeChange: (hoursStartTime) => hoursStartTimeChange(hoursStartTime),
        onHoursEndTimeChange: (hoursEndTime) => hoursEndTimeChange(hoursEndTime)
    }
)(AreaHours);

export const RouterConnectedHours = withRouter(ConnectedHours);
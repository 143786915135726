import * as React from "react";
import {connect} from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import styled from 'styled-components';
import {IGraphBeer} from "../../hf-admin-types";
import {IAppState} from "../../react-app-env";
import {
    beerAbvChange, beerBtdChange,
    beerDescChange,
    beerIbuChange,
    beerLevelChange, beerNameChange,
    beerPriceChange,
    beerSizeChange, beerStyleChange, clearBeer, createBeer,
    getBeers, selectBeer, updateBeer
} from "./beers-actions";
import {hasValue, nameMatch} from "../../common/utils";

const BeerStyles = styled.div`
    padding-top: 15px;       
    padding-left: 15px;
    padding-right: 15px;
    
    option[value=""][disabled] {
        display: none;
    }
`

interface IBeerProps {
    beerId: string;
    beerName: string;
    beerDescription: string;
    beerStyle: string;
    beerBeerStyle: string;
    beerPrice: number;
    beerSize: string;
    beerAbv: number;
    beerIbu: number;
    beerLevel: number;
    beerBeyondTheDoor: boolean;

    beers: IGraphBeer[];
    getBeers: () => { type: string };
    selectBeer: (payload) => { type: string, payload };
    clearBeer: () => { type: string };
    // getBeers.js: () => { type: 'BEERS_REQUEST' };

    createBeer: (payload) => { type: string, payload };
    updateBeer: (payload) => { type: string, payload };

    onBeerNameChange: (beerName) => { type: string, beerName};
    onBeerStyleChange: (style) => { type: string, style};
    onBeerAbvChange: (abv) => { type: string, abv};
    onBeerIbuChange: (ibu) => { type: string, ibu};
    onBeerDescChange: (desc) => { type: string, desc};
    onBeerPriceChange: (price) => { type: string, price};
    onBeerSizeChange: (size) => { type: string, size};
    onBeerLevelChange: (level) => { type: string, level};
    onBeerBtdChange: (btd) => { type: string, btd};

    // onBeerNameChange: (name) => { type: 'BEERS_NAME_CHANGE', name};
    // onBeerStyleChange: (style) => { type: 'BEERS_STYLE_CHANGE', style};
    // onBeerAbvChange: (abv) => { type: 'BEERS_ABV_CHANGE', abv};
    // onBeerIbuChange: (ibu) => { type: 'BEERS_IBU_CHANGE', ibu};
    // onBeerDescChange: (desc) => { type: 'BEERS_DESC_CHANGE', desc};
    // onBeerPriceChange: (price) => { type: 'BEERS_PRICE_CHANGE', price};
    // onBeerSizeChange: (size) => { type: 'BEERS_SIZE_CHANGE', size};
    // onBeerLevelChange: (level) => { type: 'BEERS_LEVEL_CHANGE', level};
    // onBeerBtdChange: (btd) => { type: 'BEERS_BTD_CHANGE', btd};

}

export class AreaBeer extends React.Component<IBeerProps> {

    componentDidMount() {
        this.props.getBeers();
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const params = {
            name: this.props.beerName,
            description: this.props.beerDescription,
            style: this.props.beerStyle,
            price: this.props.beerPrice,
            size: this.props.beerSize,
            abv: this.props.beerAbv,
            ibu: this.props.beerIbu,
            level: this.props.beerLevel,
            beyondTheDoor: this.props.beerBeyondTheDoor
        };

        this.props.createBeer(params);
    }

    handleModify = (e) => {
        e.preventDefault();

        const params = {
            id: this.props.beerId,
            name: this.props.beerName,
            description: this.props.beerDescription,
            style: this.props.beerStyle,
            price: this.props.beerPrice,
            size: this.props.beerSize,
            abv: this.props.beerAbv,
            ibu: this.props.beerIbu,
            level: this.props.beerLevel,
            beyondTheDoor: this.props.beerBeyondTheDoor
        };

        this.props.updateBeer(params);
    }

    handleBeerNameChange = (e) => {
        const name = e.target.value;

        this.props.onBeerNameChange(name);

        const match = nameMatch(this.props.beers, name, 'name');

        if(match) {
            this.props.selectBeer(match.id);
        } else if(this.props.beerId) {
            this.props.clearBeer();
        }
    }

    handleBeerAbvChange = (e) => {
        // const abv = parseFloat(e.target.value) ? parseFloat(e.target.value) : '';
        const abv = e.target.value;

        this.props.onBeerAbvChange(abv);
    }

    handleBeerIbuChange = (e) => {
        const ibu = hasValue(e.target.value) ? parseInt(e.target.value) : '';

        this.props.onBeerIbuChange(ibu);
    }

    handleBeerDescChange = (e) => {
        const desc = e.target.value;

        this.props.onBeerDescChange(desc);
    }


    handleBeerStyleChange = (e) => {
        const style = e.target.value;

        this.props.onBeerStyleChange(style);
    }

    handleBeerPriceChange = (e) => {
        const price = e.target.value;

        this.props.onBeerPriceChange(price);
    }

    handleBeerLevelChange = (e) => {
        const level = e.target.value;

        this.props.onBeerLevelChange(level);
    }

    handleSizeChange = (e) => {
        const size = e.target.value;

        this.props.onBeerSizeChange(size);
    }

    handleBtdChange = (e) => {
        const btd = e.target.checked;

        this.props.onBeerBtdChange(btd);
    }


    render() {
        return (
            <BeerStyles>
                <h1>Beers</h1>

                <form>

                    <div className="form-group">
                        <label htmlFor="beerList">Beer Name</label>
                        <input list="beerList"
                               id="beerName"
                               className="form-control"
                               autoComplete="off"
                               onChange={this.handleBeerNameChange}
                               value={this.props.beerName} />
                        <datalist id="beerList">
                            {this.props.beers.map((beer: IGraphBeer) => (<option key={beer.id} data-key={beer.id} value={beer.name}></option>))}
                        </datalist>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="beerStyle">Style</label>
                                <input id="beerStyle"
                                       className="form-control"
                                       onChange={this.handleBeerStyleChange}
                                       value={this.props.beerStyle} />
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="form-group">
                                <label htmlFor="beerAbv">ABV</label>
                                <div className="input-group">
                                    <input id="beerAbv"
                                           type="number"
                                           className="form-control"
                                           onChange={this.handleBeerAbvChange}
                                           value={this.props.beerAbv} />
                                    <div className="input-group-append">
                                        <span className="input-group-text">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3">
                            <div className="form-group">
                                <label htmlFor="beerIbu">IBU</label>
                                <input id="beerIbu"
                                       type="number"
                                       className="form-control"
                                       onChange={this.handleBeerIbuChange}
                                       value={this.props.beerIbu} />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="beerDesc">Description (
                            <span className={this.props.beerDescription.length > 180 ? 'bg-danger text-white' : ''}>{this.props.beerDescription.length}</span>
                            )</label>
                        <textarea id="beerDesc"
                               rows={3}
                               className="form-control"
                               onChange={this.handleBeerDescChange}
                               value={this.props.beerDescription}>
                        </textarea>
                    </div>


                    {/* TODO: Connect Tap on Create */}

                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="beerPrice">Price</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">$</span>
                                    </div>
                                    <input id="beerPrice"
                                           type="number"
                                           className="form-control"
                                           onChange={this.handleBeerPriceChange}
                                           value={this.props.beerPrice} />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="beerSize">Size</label>
                                {/* TODO: Size Enum API */}
                                <select required value={this.props.beerSize} className="form-control" id="beerSize"
                                        onChange={this.handleSizeChange}>
                                    <option value="" disabled></option>
                                    <option>Pint</option>
                                    <option>Goblet</option>
                                    <option>Tumbler</option>
                                    <option>Crowler</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="beerBeyondTheDoor" checked={this.props.beerBeyondTheDoor}
                                    onChange={this.handleBtdChange}
                                />
                                <label className="form-check-label" htmlFor="beerBeyondTheDoor">Beyond the Door</label>
                            </div>
                        </div>
                        {/*<div className="col">*/}
                            {/*<div className="form-check">*/}
                                {/*<input type="checkbox" className="form-check-input" id="beerProgram" checked={this.props.beerBeyondTheDoor}*/}
                                       {/*onChange={this.handleBtdChange}*/}
                                {/*/>*/}
                                {/*<label className="form-check-label" htmlFor="beerBeyondTheDoor">Beyond the Door</label>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>

                    <div className="btn-toolbar pt-3" role="toolbar">
                        {!this.props.beerId && <button onClick={this.handleSubmit} className="btn btn-primary">Submit</button>}
                        {this.props.beerId && <button onClick={this.handleModify} className="btn btn-warning">Modify</button>}
                    </div>
                </form>
            </BeerStyles>

        );
    }
}

export const mapStateToProps = (state: IAppState) => ({
    beerId: state.beer.beerId,
    beerName: state.beer.beerName,
    beerDescription: state.beer.beerDescription,
    beerStyle: state.beer.beerStyle,
    beerBeerStyle: state.beer.beerBeerStyle,
    beerPrice: state.beer.beerPrice,
    beerSize: state.beer.beerSize,
    beerAbv: state.beer.beerAbv,
    beerIbu: state.beer.beerIbu,
    beerLevel: state.beer.beerLevel,
    beerBeyondTheDoor: state.beer.beerBeyondTheDoor,
    beers: state.beer.beers
})

export const ConnectedBeer = connect(
    mapStateToProps,
    {
        getBeers: () => getBeers(),
        selectBeer: (id) => selectBeer(id),
        clearBeer: () => clearBeer(),
        createBeer: (beer) => createBeer(beer),
        updateBeer: (beer) => updateBeer(beer),
        onBeerNameChange: (beerName) => beerNameChange(beerName),
        onBeerStyleChange: (style) => beerStyleChange(style),
        onBeerAbvChange: (abv) => beerAbvChange(abv),
        onBeerIbuChange: (ibu) => beerIbuChange(ibu),
        onBeerDescChange: (desc) => beerDescChange(desc),
        onBeerPriceChange: (price) => beerPriceChange(price),
        onBeerSizeChange: (size) => beerSizeChange(size),
        onBeerLevelChange: (level) => beerLevelChange(level),
        onBeerBtdChange: (btd) => beerBtdChange(btd)
    }
)(AreaBeer);

export const RouterConnectedBeer = withRouter(ConnectedBeer);


import * as React from "react";
import {connect} from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import {IAppState} from "../../react-app-env";
import {Navbar, Nav, NavItem, NavbarBrand, NavbarToggler, Collapse} from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';

import netlifyIdentity from "netlify-identity-widget";
import {getUserSuccess} from "../../common/user/user-actions";


interface INavbarProps {
    email: string;
    isAuth: boolean;
}

export class AreaNavbar extends React.Component<INavbarProps> {
    // constructor(props) {
    //     super(props);
    //
    //     this.toggle = this.toggle.bind(this);
    //     this.state = {
    //         isOpen: false
    //     };
    // }
    // toggle() {
    //     this.setState({
    //         isOpen: !this.state.isOpen
    //     });
    // }

    handleIdentityClick = (e) => {
        netlifyIdentity.open();
    }

    render() {
        return (
            <nav className="navbar navbar-expand-sm navbar-light bg-light">
                <NavLink to="/" className="navbar-brand">HF Admin</NavLink>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">

                        {this.props.isAuth &&
                            <li className="nav-item">
                                <NavLink to="/taps" className="nav-link">Taps</NavLink>
                            </li>
                        }
                        {this.props.isAuth &&
                            <li className="nav-item">
                                <NavLink to="/beers" className="nav-link">Beers</NavLink>
                            </li>
                        }
                        {this.props.isAuth &&
                        <li className="nav-item">
                            <NavLink to="/menu" className="nav-link">Menu</NavLink>
                        </li>
                        }
                        {this.props.isAuth &&
                        <li className="nav-item">
                            <NavLink to="/events" className="nav-link">Events</NavLink>
                        </li>
                        }
                        {this.props.isAuth &&
                        <li className="nav-item">
                            <NavLink to="/hours" className="nav-link">Hours</NavLink>
                        </li>
                        }
                    </ul>


                    { this.props.isAuth
                        ?(<ul className="navbar-nav">
                            <li className="nav-item">
                                <span className="navbar-text">{this.props.email}</span>
                            </li>
                            <li className="nav-item">
                                <NavLink to="#" onClick={this.handleIdentityClick} className="nav-link">Logout</NavLink>
                            </li>
                        </ul>

                        )
                        :(<ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink to="#" onClick={this.handleIdentityClick} className="nav-link">Login</NavLink>
                            </li>
                        </ul>)
                    }

                </div>
            </nav>
        );
    }
}

export const mapStateToProps = (state: IAppState) => ({
    email: state.user.email,
    isAuth: state.user.isAuth
})

export const ConnectedNavbar = connect(
    mapStateToProps,
    {
    }
)(AreaNavbar);

// export const RouterConnectedNavbar = withRouter(ConnectedNavbar);


import * as React from "react";
import {connect} from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {IGraphBeer, IGraphTap, ITapBeer} from "../../hf-admin-types";
import {IAppState} from "../../react-app-env";
import {unconnectTappedBeer, getTaps, connectTappedBeer, netlifyBuild} from "./taps-actions";
import {TapsRow} from "./TapsRow";
import {getBeers} from "../beer/beers-actions";

const TapsStyles = styled.div`
    padding-top: 15px;       
    padding-left: 15px;
    padding-right: 15px;
`

interface ITapsProps {
    taps: IGraphTap[];
    tapsLoaded: boolean;
    beers: IGraphBeer[];
    beersLoaded: boolean;
    tapsChanged: boolean;
    getTaps: () => { type: 'TAPS_REQUEST' };
    getBeers: () => { type: 'BEERS_REQUEST' };
    connectTappedBeer: (tapBeer) => {type: 'CONNECT_TAPPED_BEER', payload: ITapBeer};
    unconnectTappedBeer: (id) => {type: 'UNCONNECT_TAPPED_BEER', payload: string};
    netlifyBuild: () => { type: 'NETLIFY_BUILD' };
}

export class AreaTaps extends React.Component<ITapsProps> {

    componentDidMount() {
        !this.props.tapsLoaded ? this.props.getTaps() : null;
        !this.props.beersLoaded ? this.props.getBeers() : null;
    }

    handleUnconnect = (tap) => {
        this.props.unconnectTappedBeer(tap.id);
    }

    handleConnect = (tap, beer) => {
        this.props.connectTappedBeer({tapId: tap, beerId: beer});
        // console.log("Taps:", tap, beer);
    }

    handleNetlifyBuild = () => {
        this.props.netlifyBuild();
    }

    render() {
        return (
            <TapsStyles>
                <div className="d-flex justify-content-between">
                    <div><h1 className='pb-3'>Taps</h1></div>
                    <div>
                        <button type="button" className="btn btn-danger" onClick={this.handleNetlifyBuild} disabled={!this.props.tapsChanged}>Build Site</button>
                    </div>
                </div>


                <table className="table table-hover">
                    <tbody>

                        {this.props.taps.map((tap: any) => (
                            <TapsRow tap={tap}
                                     onConnect={this.handleConnect}
                                     onUnconnect={this.handleUnconnect}
                                     beers={this.props.beers}
                                     key={tap.id} />
                        ))}
                    </tbody>
                </table>
            </TapsStyles>
        );
    }
}

export const mapStateToProps = (state: IAppState) => ({
    taps: state.taps.taps,
    beers: state.beer.beers,
    beersLoaded: state.beer.beersLoaded,
    tapsChanged: state.taps.tapsChanged
})

export const ConnectedAreaTaps = connect(
    mapStateToProps,
    {
        getTaps: () => getTaps(),
        getBeers: () => getBeers(),
        unconnectTappedBeer: (id) => unconnectTappedBeer(id),
        connectTappedBeer: (tapBeer) => connectTappedBeer(tapBeer),
        netlifyBuild: () => netlifyBuild()
    }
)(AreaTaps);

export const RouterConnectedAreaTaps = withRouter(ConnectedAreaTaps);


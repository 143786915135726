
const initialState = {
    events: [],
    eventsLoaded: false,
    eventsLoading: false,
    eventId: '',
    eventName: '',
    eventStartTime: '',
    eventEndTime: '',
    eventDisplayDate: '',
    eventDescription: '',
    eventLongDescription: '',
    eventType: '',
    eventMarquee: false,
}

export const eventReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'EVENTS_REQUEST':
            return {...state, eventsLoading: true };
        case 'EVENTS_REQUEST_SUCCESS':
            return {...state, events: action.payload, eventsLoaded: true, eventsLoading: false };


        // case 'BEER_SELECT':
        //     return {...state, eventsLoading: true };
        // case 'BEER_SELECT_SUCCESS':
        //     return {...state,
        //         beerAbv: action.payload.abv || '',
        //         beerBeyondTheDoor: action.payload.beyondTheDoor || false,
        //         beerDescription: action.payload.description || '',
        //         beerIbu: action.payload.ibu || '',
        //         beerId: action.payload.id || '',
        //         beerLevel: action.payload.level || '',
        //         beerName: action.payload.name || '',
        //         beerPrice: action.payload.price || '',
        //         beerSize: action.payload.size || '',
        //         // beerStyle: action.payload.style || textDecode(action.payload.beerStyle)
        //         beerStyle: action.payload.style || '',
        //         eventsLoading: false
        //     };
        // case 'BEER_SELECT_ERROR':
        //     return {...state, eventsLoading: false };
        // case 'BEER_CLEAR':
        //     return {...state,
        //         beerId: '',
        //         beerDescription: '',
        //         beerStyle: '',
        //         beerBeerStyle: '',
        //         beerPrice: '',
        //         beerSize: '',
        //         beerAbv: '',
        //         beerIbu: '',
        //         beerLevel: '',
        //         beerBeyondTheDoor: false
        //     };
        //
        // case 'BEER_CREATE':
        //     return {...state, beerId: action.payload.id, eventsLoading: true };
        // case 'BEER_CREATE_SUCCESS':
        //     return {...state, eventsLoading: false };
        // case 'BEER_CREATE_ERROR':
        //     return {...state, eventsLoading: false };
        //
        // case 'BEER_UPDATE':
        //     return {...state, eventsLoading: true };
        // case 'BEER_UPDATE_SUCCESS':
        //     return {...state, eventsLoading: false };
        // case 'BEER_UPDATE_ERROR':
        //     return {...state, eventsLoading: false };


        case 'EVENT_ID_CHANGE':
            return {...state, eventId: action.id };
        case 'EVENT_NAME_CHANGE':
            return {...state, eventName: action.name };
        case 'EVENT_START_TIME_CHANGE':
            return {...state, eventStartTime: action.startTime };
        case 'EVENT_END_TIME_CHANGE':
            return {...state, eventEndTime: action.endTime };
        case 'EVENT_DISPLAY_DATE_CHANGE':
            return {...state, eventDisplayDate: action.displayDate };
        case 'EVENT_DESCRIPTION_CHANGE':
            return {...state, eventDescription: action.description };
        case 'EVENT_LONG_DESCRIPTION_CHANGE':
            return {...state, eventLongDescription: action.longDescription };
        case 'EVENT_TYPE_CHANGE':
            return {...state, eventType: action.eventType };
        case 'EVENT_MARQUEE_CHANGE':
            return {...state, eventMarquee: action.marquee };


        default:
            return state;
    }
}
import {IAction} from "../../react-app-env";
import {IGraphBeer} from "../../hf-admin-types";
import {toast} from "react-toastify";


export type GetBeers = IAction<'BEERS_REQUEST'>;
export const getBeers = () => ({ type: 'BEERS_REQUEST' });

export type GetBeersUpdateSuccess = IAction<'BEERS_REQUEST_UPDATE_SUCCESS'>;
export const getBeersUpdateSuccess = () => ({ type: 'BEERS_REQUEST_UPDATE_SUCCESS' });

export type GetBeersSuccess = IAction<'BEERS_REQUEST_SUCCESS', { payload: Object[] }>;
export const getBeersSuccess = (beers: Object) => ({ type: 'BEERS_REQUEST_SUCCESS', payload: beers });

export type GetBeersError = IAction<'BEERS_REQUEST_ERROR', { payload: Error }>;
export const getBeersError = (error: Error) => ({ type: 'BEERS_REQUEST_ERROR', payload: { error } });

export type SelectBeer = IAction<'BEER_SELECT', { payload: string }>;
export const selectBeer = (beerId: string) => ({ type: 'BEER_SELECT', payload: beerId});

export type SelectBeerSuccess = IAction<'BEER_SELECT_SUCCESS', { payload: Object[] }>;
export const selectBeerSuccess = (beers: Object) => ({ type: 'BEER_SELECT_SUCCESS', payload: beers });

export type SelectBeerError = IAction<'BEER_SELECT_ERROR', { payload: Error }>;
export const selectBeerError = (error: Error) => ({ type: 'BEER_SELECT_ERROR', payload: { error } });

export type ClearBeer = IAction<'BEER_CLEAR'>;
export const clearBeer = () => ({ type: 'BEER_CLEAR' });



export type CreateBeer = IAction<'BEER_CREATE', { payload: IGraphBeer }>;
export const createBeer = (payload: IGraphBeer) => ({ type: 'BEER_CREATE', payload});

export type CreateBeerSuccess = IAction<'BEER_CREATE_SUCCESS', { payload: Object }>;
export const createBeerSuccess = (beer: Object) => {
    toast.success('Beer Created Successfully');
    return ({type: 'BEER_CREATE_SUCCESS', payload: beer})
};

export type CreateBeerError = IAction<'BEER_CREATE_ERROR', { payload: Error }>;
export const createBeerError = (error: Error) => ({ type: 'BEER_CREATE_ERROR', payload: { error } });

export type UpdateBeer = IAction<'BEER_UPDATE', { payload: IGraphBeer }>;
export const updateBeer = (payload: IGraphBeer) => ({ type: 'BEER_UPDATE', payload});

export type UpdateBeerSuccess = IAction<'BEER_UPDATE_SUCCESS', { payload: Object }>;
export const updateBeerSuccess = (beer: Object) => {
    toast.success('Beer Updated Successfully');
    return ({type: 'BEER_UPDATE_SUCCESS', payload: beer})
};

export type UpdateBeerError = IAction<'BEER_UPDATE_ERROR', { payload: Error }>;
export const updateBeerError = (error: Error) => {
    toast.error(`Beer not updated: ${error}`, {autoClose: false});
    return ({ type: 'BEER_UPDATE_ERROR', payload: { error } })
};

export type BeerNameChange = IAction<'BEER_NAME_CHANGE', { beerName: string }>;
export const beerNameChange = (beerName: string) => ({ type: 'BEER_NAME_CHANGE', beerName});

export type BeerStyleChange = IAction<'BEER_STYLE_CHANGE', { style: string }>;
export const beerStyleChange = (style: string) => ({ type: 'BEER_STYLE_CHANGE', style});

export type BeerIbuChange = IAction<'BEER_IBU_CHANGE', { ibu: number }>;
export const beerIbuChange = (ibu: number) => ({ type: 'BEER_IBU_CHANGE', ibu});

export type BeerAbvChange = IAction<'BEER_ABV_CHANGE', { abv: number }>;
export const beerAbvChange = (abv: number) => ({ type: 'BEER_ABV_CHANGE', abv});

export type BeerDescChange = IAction<'BEER_DESC_CHANGE', { desc: string }>;
export const beerDescChange = (desc: string) => ({ type: 'BEER_DESC_CHANGE', desc});

export type BeerPriceChange = IAction<'BEER_PRICE_CHANGE', { price: number }>;
export const beerPriceChange = (price: number) => ({ type: 'BEER_PRICE_CHANGE', price});
export type BeerSizeChange = IAction<'BEER_SIZE_CHANGE', { size: string }>;
export const beerSizeChange = (size: string) => ({ type: 'BEER_SIZE_CHANGE', size});
export type BeerLevelChange = IAction<'BEER_LEVEL_CHANGE', { level: number }>;
export const beerLevelChange = (level: number) => ({ type: 'BEER_LEVEL_CHANGE', level});
export type BeerBtdChange = IAction<'BEER_BTD_CHANGE', { btd: boolean }>;
export const beerBtdChange = (btd: boolean) => ({ type: 'BEER_BTD_CHANGE', btd});





import {IAction} from "../../react-app-env";


export type GetHomeTest = IAction<'HOMETEST_REQUEST'>;
export const getHomeTest = () => ({ type: 'HOMETEST_REQUEST' });

export type GetHomeTestSuccess = IAction<'HOMETEST_REQUEST_SUCCESS', { payload: any }>;
export const getHomeTestSuccess = (test: Object) => ({ type: 'HOMETEST_REQUEST_SUCCESS', payload: test });

export type GetHomeTestError = IAction<'HOMETEST_REQUEST_ERROR', { payload: Error }>;
export const getHomeTestError = (error: Error) => ({ type: 'HOMETEST_REQUEST_ERROR', payload: { error } });
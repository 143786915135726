import {IAction} from "../../react-app-env";
import {IGraphHours} from "../../hf-admin-types";
import {toast} from "react-toastify";




export type GetHours = IAction<'HOURS_REQUEST'>;
export const getHours = () => ({ type: 'HOURS_REQUEST' });

export type GetHoursUpdateSuccess = IAction<'HOURS_REQUEST_UPDATE_SUCCESS'>;
export const getHoursUpdateSuccess = () => ({ type: 'HOURS_REQUEST_UPDATE_SUCCESS' });

export type GetHoursSuccess = IAction<'HOURS_REQUEST_SUCCESS', { payload: Object[] }>;
export const getHoursSuccess = (hours: Object) => ({ type: 'HOURS_REQUEST_SUCCESS', payload: hours });

export type GetHoursError = IAction<'HOURS_REQUEST_ERROR', { payload: Error }>;
export const getHoursError = (error: Error) => ({ type: 'HOURS_REQUEST_ERROR', payload: { error } });

export type SelectHour = IAction<'HOUR_SELECT', { payload: string }>;
export const selectHour = (beerId: string) => ({ type: 'HOUR_SELECT', payload: beerId});

export type SelectHourSuccess = IAction<'HOUR_SELECT_SUCCESS', { payload: Object[] }>;
export const selectHourSuccess = (hours: Object) => ({ type: 'HOUR_SELECT_SUCCESS', payload: hours });

export type SelectHourError = IAction<'HOUR_SELECT_ERROR', { payload: Error }>;
export const selectHourError = (error: Error) => ({ type: 'HOUR_SELECT_ERROR', payload: { error } });

export type ClearHour = IAction<'HOUR_CLEAR'>;
export const clearHour = () => ({ type: 'HOUR_CLEAR' });





export type UpdateHour = IAction<'HOUR_UPDATE', { payload: IGraphHours }>;
export const updateHour = (payload: IGraphHours) => ({ type: 'HOUR_UPDATE', payload});

export type UpdateHourSuccess = IAction<'HOUR_UPDATE_SUCCESS', { payload: Object }>;
export const updateHourSuccess = (hour: Object) => {
    toast.success('Hour Updated Successfully');
    return ({type: 'HOUR_UPDATE_SUCCESS', payload: hour})
};

export type UpdateHourError = IAction<'HOUR_UPDATE_ERROR', { payload: Error }>;
export const updateHourError = (error: Error) => {
    toast.error(`Hour not updated: ${error}`, {autoClose: false});
    return ({ type: 'HOUR_UPDATE_ERROR', payload: { error } })
};



export type DeleteHour = IAction<'HOUR_DELETE', { payload: IGraphHours }>;
export const deleteHour = (payload: IGraphHours) => ({ type: 'HOUR_DELETE', payload});

export type DeleteHourSuccess = IAction<'HOUR_DELETE_SUCCESS', { payload: Object }>;
export const deleteHourSuccess = (hour: Object) => {
    toast.success('Hour Deleted Successfully');
    return ({type: 'HOUR_DELETE_SUCCESS', payload: hour})
};

export type DeleteHourError = IAction<'HOUR_DELETE_ERROR', { payload: Error }>;
export const deleteHourError = (error: Error) => {
    toast.error(`Hour not deleted: ${error}`, {autoClose: false});
    return ({ type: 'HOUR_DELETE_ERROR', payload: { error } })
};











export type CreateHours = IAction<'HOURS_CREATE', { payload: IGraphHours }>;
export const createHours = (payload: IGraphHours) => ({ type: 'HOURS_CREATE', payload});

export type CreateHoursSuccess = IAction<'HOURS_CREATE_SUCCESS', { payload: Object }>;
export const createHoursSuccess = (hour: Object) => {
    toast.success('Special Hours Created Successfully');
    return ({type: 'HOURS_CREATE_SUCCESS', payload: hour})
};

export type CreateHoursError = IAction<'HOURS_CREATE_ERROR', { payload: Error }>;
export const createHoursError = (error: Error) => ({ type: 'HOURS_CREATE_ERROR', payload: { error } });




export type HoursDateChange = IAction<'HOURS_DATE_CHANGE', { hoursDate: string }>;
export const hoursDateChange = (hoursDate: string) => ({ type: 'HOURS_DATE_CHANGE', hoursDate});

export type HoursAbbrChange = IAction<'HOURS_ABBR_CHANGE', { hoursAbbr: string }>;
export const hoursAbbrChange = (hoursAbbr: string) => ({ type: 'HOURS_ABBR_CHANGE', hoursAbbr});

export type HoursClosedChange = IAction<'HOURS_CLOSED_CHANGE', { hoursClosed: boolean }>;
export const hoursClosedChange = (hoursClosed: boolean) => ({ type: 'HOURS_CLOSED_CHANGE', hoursClosed});

export type HoursStartTimeChange = IAction<'HOURS_START_TIME_CHANGE', { hoursStartTime: string }>;
export const hoursStartTimeChange = (hoursStartTime: string) => ({ type: 'HOURS_START_TIME_CHANGE', hoursStartTime});

export type HoursEndTimeChange = IAction<'HOURS_END_TIME_CHANGE', { hoursEndTime: string }>;
export const hoursEndTimeChange = (hoursEndTime: string) => ({ type: 'HOURS_END_TIME_CHANGE', hoursEndTime});
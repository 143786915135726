import {IBeerState} from "../../react-app-env";
import {Reducer} from "redux";
import {textDecode} from "../../common/utils";


const initialState = {
    beers: [],
    beersLoaded: false,
    beersLoading: false,
    beerId: '',
    beerName: '',
    beerDescription: '',
    beerStyle: '',
    beerBeerStyle: '',
    beerPrice: '',
    beerSize: '',
    beerAbv: '',
    beerIbu: '',
    beerLevel: '',
    beerBeyondTheDoor: false
}

// export const beerReducer: Reducer<IBeerState> = (state = initialState, action) => {
export const beerReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'BEERS_REQUEST':
            return {...state, beersLoading: true };
        case 'BEERS_REQUEST_SUCCESS':
            return {...state, beers: action.payload, beersLoaded: true, beersLoading: false };


        case 'BEER_SELECT':
            return {...state, beersLoading: true };
        case 'BEER_SELECT_SUCCESS':
            return {...state,
                beerAbv: action.payload.abv || 0,
                beerBeyondTheDoor: action.payload.beyondTheDoor || false,
                beerDescription: action.payload.description || '',
                beerIbu: action.payload.ibu || 0,
                beerId: action.payload.id || '',
                beerLevel: action.payload.level || '',
                beerName: action.payload.name || '',
                beerPrice: action.payload.price || 0,
                beerSize: action.payload.size || '',
                // beerStyle: action.payload.style || textDecode(action.payload.beerStyle)
                beerStyle: action.payload.style || '',
                beersLoading: false
            };
        case 'BEER_SELECT_ERROR':
            return {...state, beersLoading: false };
        case 'BEER_CLEAR':
            return {...state,
                beerId: '',
                beerDescription: '',
                beerStyle: '',
                beerBeerStyle: '',
                beerPrice: '',
                beerSize: '',
                beerAbv: '',
                beerIbu: '',
                beerLevel: '',
                beerBeyondTheDoor: false
            };

        case 'BEER_CREATE':
            return {...state, beersLoading: true };
        case 'BEER_CREATE_SUCCESS':
            return {...state, beerId: action.payload.records[0].id, beersLoading: false };
        case 'BEER_CREATE_ERROR':
            return {...state, beersLoading: false };

        case 'BEER_UPDATE':
            return {...state, beersLoading: true };
        case 'BEER_UPDATE_SUCCESS':
            return {...state, beersLoading: false };
        case 'BEER_UPDATE_ERROR':
            return {...state, beersLoading: false };


        case 'BEER_NAME_CHANGE':
            return {...state, beerName: action.beerName };
        case 'BEER_STYLE_CHANGE':
            return {...state, beerStyle: action.style };
        case 'BEER_ABV_CHANGE':
            return {...state, beerAbv: action.abv };
        case 'BEER_IBU_CHANGE':
            return {...state, beerIbu: action.ibu };
        case 'BEER_DESC_CHANGE':
            return {...state, beerDescription: action.desc };
        case 'BEER_PRICE_CHANGE':
            return {...state, beerPrice: action.price };
        case 'BEER_SIZE_CHANGE':
            return {...state, beerSize: action.size };
        case 'BEER_LEVEL_CHANGE':
            return {...state, beerLevel: action.level };
        case 'BEER_BTD_CHANGE':
            return {...state, beerBeyondTheDoor: action.btd };


        default:
            return state;
    }
}
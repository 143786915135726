import {ofType} from "redux-observable";
import {map, switchMap} from "rxjs/operators";
import {authAjax} from "../../common/utils";
import {AjaxResponse} from "rxjs/ajax";
import {getBeersSuccess} from "../beer/beers-actions";
import {getEventsSuccess} from "./event-actions";


import axios from 'axios'


export const getEventsEpic = action$ =>
    action$.pipe(
        ofType('EVENTS_REQUEST'),
        switchMap(action => authAjax.post({
            url: `/.netlify/functions/events`,
            // method: 'GET',
            // route: 'Events',
            // responseType: 'json',
            body: ''
        })),
        map((response: AjaxResponse) => {
            const TODAY = new Date().toJSON().slice(0, 10);

            const events = response.response.records.map(event => {
                    const mappedEvent = {
                        id: event.id,
                        description: event.fields.Description,
                        displayDate: event.fields.DisplayDate,
                        eventType: event.fields.EventType,
                        image: event.fields.Image,
                        longDescription: event.fields.LongDescription,
                        marquee: event.fields.Marquee,
                        name: event.fields.Name,
                        startDate: event.fields.EventDate
                    };

                    if (event.fields.Image) {
                        // getBase64(event.fields.Image[0].url)
                        getBase64(event.fields.Image[0].thumbnails.large.url)
                            .then(res => {
                                mappedEvent['imageBin'] = res;
                            });
                    }

                mappedEvent.displayDate = mappedEvent.displayDate || mappedEvent.startDate;
                mappedEvent['sort'] = sortEventMap(mappedEvent);

                return mappedEvent;
                }
            ).filter(event => event.displayDate>=TODAY).sort((a,b) => (a.displayDate > b.displayDate) ? 1 : ((b.displayDate > a.displayDate) ? -1 : (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0)));

            const grp = groupByArray(events, 'displayDate');

            console.log(grp);

            return getEventsSuccess(grp)
        })
        // .catch(e => Observable.of(getBeersError(e.message)))
    );








// TODO: Move to Utils
async function getBase64(url) {
    const r = await axios
        .get(url, {
            responseType: 'arraybuffer'
        })

    const buf = Buffer.from(r.data, 'binary').toString('base64');

    return `data:${r.headers['content-type']};base64,${buf}`;
}



// Used to group by dates on Events
function groupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key];
        let el = rv.find((r) => r && r.key === v);
        if (el) { el.values.push(x); } else { rv.push({ key: v, values: [x] }); }
        return rv;
    }, []);
}

function sortEventMap(events) {
    var eventType = {
        'Private Event': 0,
        'Special Event': 1,
        'Beer Release': 1.4,
        'Special Hours': 1.5,
        'Weekly Event': 2,
        'Food Truck': 9,
        'Beer Festival': 10,
        'default': 8
    };
    return (eventType[events.eventType] || eventType['default']);
}
import netlifyIdentity from "netlify-identity-widget";
import {ajax} from "rxjs/ajax";

export const genericAuthAjax = (method: string) => (
    obj = {}
) => {
    const headers = {
        //"Content-Type": "application/json"
    };

    if(!netlifyIdentity.currentUser())
        throw new Error('no user token found')
    else {
        return netlifyIdentity.currentUser().jwt().then((token) => {
            return ajax({...obj,
                method: method,
                responseType: 'json',
                headers: {...headers, Authorization: `Bearer ${token}`}
            }).toPromise().then(res => res);
        })
    }
}

export const authAjax = {
    get: genericAuthAjax('GET'),
    post: genericAuthAjax('POST'),
    put: genericAuthAjax('PUT'),
    delete: genericAuthAjax('DELETE')
};

export const nameMatch = (obj, val, prop) => {
    return obj.find(el => {
        return el[prop] == val;
    });
}

export function textDecode (text) {
    return text.replace(/_/g, ' ');
}

// Util for handling valid 0 values
//   -Replaces 0 with '0' which is truthy
export const hasValue = (value) => {
    if(value == 0) return '0';
    return value;
}